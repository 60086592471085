import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Container,
    Divider,
    Grid,
    Input,
    InputLabel,
    makeStyles, MenuItem,
    Paper,
    Select, TextField,
    Tooltip
} from "@material-ui/core";
import ItemList from "./ItemList";
import AddIcon from '@material-ui/icons/Add';
import useOnMouseOver from "./hooks/useOnMouseOver";
import MoreActionsMenu from "./MoreActionsMenu";
import {useLocation, useParams} from "react-router";
import MenuBar from "./MenuBar";
import {LineItemType, SpecCostSheetType} from "./types";
import {DateTime} from "luxon";
import {calcCostFromLines} from "./services/calculations";
import Typography from "@material-ui/core/Typography";
import {useEventCallback, useObservable} from "rxjs-hooks";
import {archiveSpecCostSheet, saveSpecCostSheet, specCostSheet, specCostSheetBySpecNo} from "./services/rpcs";
import {useHistory} from "react-router-dom";
import {Alert, AlertTitle} from "@material-ui/lab";
import {useAgingCosts} from "./hooks/useAgingCosts";
import PriceChange from "./componants/PriceChange";

const width = 880;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    main: {
        height: '100%',
        width: width,
        minWidth: width - 100,
        maxWith: width + 100,
        padding: 25,
        minHeight: '90vh'
    },
    topSection: {
        paddingBottom: 50
    },
    topHeading: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
    },
    large: {
        fontSize: 40,
        fontWeight: 500,
        textAlign: 'right'
    },
    totalLabel: {
        textAlign: 'right'
    },
    save: {
        position: 'relative',
        marginRight: 0,
        width: '50',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
}));

export const defaultLineItem: LineItemType = {
    id: null,
    name: '',
    description: '',
    importDescription: '',
    qty: "1",
    cost: '0',
    unitType: ''
};

export const defaultCostSheet: SpecCostSheetType = {
    id: null,
    customerName: '',
    specNo: '',
    description: '',
    wastePercent: .03,
    fastenerPercent: .02,
    specDate: DateTime.now().toFormat('yyyy-MM-dd'),
    lineItems: [],
    specType: 'pallet',
    hourlyRate: 65,
    notes: ''
}

const defaultTotals = {grandTotal: 0, itemsTotal: 0, waste: 0, fasteners: 0, laborTotal: 0, materialTotal: 0};

export default function CostSheet() {
    const location: any = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const {sheet: newSheet = null, parentSheet = null} = location?.state || {};
    const {id, specNo} = useParams<{ id: string, specNo: string }>();
    const [state, setState] = useState<SpecCostSheetType>(defaultCostSheet);
    const [mouseOver, setMouseOver] = useOnMouseOver();
    const sheet: SpecCostSheetType = useObservable((d, input$) => input$
        .filter(([id, specNo]) => !(specNo === 'new' || specNo === 'copy'))
        .switchMap(([id, specNo]) => {
            // console.log(id, specNo);
            return id ? specCostSheet(id) : specCostSheetBySpecNo(specNo);
        }), defaultCostSheet, [id, specNo]);
    const [totals, setTotals] = useState(defaultTotals);

    const {diff, updatedList} = useAgingCosts(state.lineItems, state.wastePercent, state.fastenerPercent);

    const [saved, setSaved] = useState(false);

    useEffect(() => {
        setTotals(calcCostFromLines(state.lineItems, (state.wastePercent || defaultCostSheet.wastePercent), state.fastenerPercent || defaultCostSheet.fastenerPercent));
    }, [state.lineItems]);

    // Copy the sheet that we get remotely to the state
    useEffect(() => setState(sheet), [sheet]);

    // Copy the sheet we get from a copy to the state
    useEffect(() => {
        const sheetWithDefaults = {
            ...defaultCostSheet,
            ...newSheet,
            lineItems: newSheet?.lineItems?.map(l => ({...defaultLineItem, ...l}))
        }
        setTimeout(() => newSheet && setState(sheetWithDefaults), 100)
    }, [newSheet]);

    const [hasChanged, setHasChanged] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const formRef = useRef<HTMLFormElement>();

    useEffect(() => setHasChanged(false), [id, specNo]); // Everytime we get a new id, reset
    // console.log('hasChanged', hasChanged);

    useEffect(() => setDisabled(state.archived), [state.archived]);

    const handleStateChangeEvent = (key) => (e) => setState(prev => ({...prev, [key]: e.target.value}));

    const handleLineItemsChange = (lineItems) => {
        // console.log('lineItems Changed', lineItems)
        setState(prev => ({...prev, lineItems}));
    };

    const [handleSave] = useEventCallback(event$ => event$
            // .do(v => console.log(v))
            .do(s => formRef?.current?.requestSubmit())
            .filter(() => formRef?.current?.checkValidity())
            .do(() => setDisableSaveButton(true))
            .flatMap((s: SpecCostSheetType) => saveSpecCostSheet(s))
            .do(({specNo}) => {
                setSaved(true);
                setDisableSaveButton(false);
                history.replace({pathname: `/cost-sheet/${specNo}`});
            })
        , {} as SpecCostSheetType
    );

    const [archiveSpecSheet] = useEventCallback(event$ => event$
            .flatMap(id => archiveSpecCostSheet(id))
            .do(() => setState(prev => ({...prev, archived: true})))
        , null
    );

    const handleArchive = () => archiveSpecSheet(state.id);

    const handleAddLine = () => {
        setState(prev => {
            // console.log('add prev', prev.lineItems);
            return ({
                ...prev,
                lineItems: [...(prev.lineItems || []), {...defaultLineItem, id: DateTime.now().toMillis()}]
            })
        });
    }

    const handleCopy = () => {
        // create a new sheet and reset the ids
        setSaved(false);
        const newSheet = {
            ...state,
            id: null,
            specDate: DateTime.now().toFormat('yyyy-MM-dd'),
            lineItems: state.lineItems.map(l => ({...l, id: null}))
        };

        history.push({pathname: '/cost-sheet/copy', state: {sheet: newSheet, parentSheet: {...state}}});
    }

    const handleCopyWithNewCosts = () => {
        // create a new sheet and reset the ids
        setSaved(false);
        const newSheet = {
            ...state,
            id: null,
            specDate: DateTime.now().toFormat('yyyy-MM-dd'),
            lineItems: state.lineItems.map(l => {
                const newCost = updatedList[l.id]?.item?.cost || l.cost;
                // console.log('new cost', newCost, l.cost)
                return {...l, id: DateTime.now().toMillis(), cost: newCost};
            })
        };

        history.push({pathname: '/cost-sheet/copy', state: {sheet: newSheet, parentSheet: {...state}}});
    }

    useEffect(() => {
        setTimeout(() => window.scrollBy({top: -window.outerHeight, behavior: 'smooth'}))
    }, [id, specNo]);

    return (<Container maxWidth="lg">
        <MenuBar
            barStyle={{maxWidth: 880, minWidth: 640}}
            title={`Spec Sheet ${state.specNo}`}
            actions={<>
                <MoreActionsMenu onCopy={handleCopy} onArchive={handleArchive} disabled={disabled}
                                 showDownload={!!sheet.s3Key}
                                 specId={sheet.id}
                />
                <Button
                    color="inherit"
                    className={classes.save}
                    variant={'outlined'}
                    onClick={() => handleSave(state)}
                    disabled={disableSaveButton || disabled}
                >
                    Save
                </Button>
            </>}
        />
        {specNo === 'copy' &&
        <Alert severity="info" style={{width: width, marginBottom: 20}}>
            <AlertTitle>Copy (Not Saved)</AlertTitle>
            This is a copy of spec <strong>{parentSheet?.specNo}</strong> that was created
            on <strong>{DateTime.fromSQL(parentSheet?.creationDate).toFormat('MM/dd/yyyy')}</strong>
        </Alert>}

        {diff !== 0 &&
        <Alert severity={diff > 0 ? 'error' : 'success'} style={{width: width, marginBottom: 20}}>
            The cost has {diff > 0 ? 'increased' : 'decreased'} by ${Math.abs(diff).toFixed(2)} since this sheet was
            created.
            <Button variant={'outlined'} style={{marginLeft: 10}} onClick={handleCopyWithNewCosts}>Apply latest
                pricing</Button>
        </Alert>}

        {saved &&
        <Alert severity={'success'} style={{width: width, marginBottom: 20}}>
            <AlertTitle>Saved</AlertTitle>
            The cost sheet for <strong>{state.customerName}</strong>, spec <strong>{state.specNo}</strong> has been
            saved.
        </Alert>}

        {specNo === 'new' &&
        <Alert severity="info" style={{width: width, marginBottom: 20}}>
            <AlertTitle>New Sheet (Not Saved)</AlertTitle>
            {/*[todo: add some copy here for creating a new sheet]*/}
        </Alert>}

        {state.archived &&
        <Alert severity="info" style={{width: width, marginBottom: 20}}>
            <AlertTitle>Archived</AlertTitle>
            This Spec Sheet has been archived.
        </Alert>}

        <Paper
            className={classes.main}
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
        >
            <form
                ref={formRef}
                onChange={e => setHasChanged(true)}
                onSubmit={e => e.preventDefault()}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} direction={'row'} className={classes.topSection}>
                            <Grid item xs={3}>
                                <Grid container direction={'column'} spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.topHeading}>Customer</Typography>
                                        <Input
                                            id="'psi-customerName'"
                                            value={state.customerName}
                                            onChange={handleStateChangeEvent('customerName')}
                                            disableUnderline={!mouseOver}
                                            required
                                            tabIndex={0}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.topHeading}>Description/Customer
                                            Item</Typography>
                                        <Input
                                            id="psi-description"
                                            value={state.description}
                                            onChange={handleStateChangeEvent('description')}
                                            disableUnderline={!mouseOver}
                                            tabIndex={2}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container direction={'column'} spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.topHeading}>Specification</Typography>
                                        <Input
                                            id="psi-specNo"
                                            disableUnderline={!mouseOver}
                                            required
                                            value={state.specNo}
                                            onChange={handleStateChangeEvent('specNo')}
                                            tabIndex={1}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.topHeading}>Date</Typography>
                                        <Input
                                            id="psi-specDate"
                                            value={DateTime.fromSQL(state.specDate).toFormat('yyyy-MM-dd')}
                                            type={'date'}
                                            onChange={e => {
                                                const d = DateTime.fromSQL(e.target.value).toFormat('yyyy-MM-dd');
                                                setState(prev => ({...prev, specDate: d}));
                                            }}
                                            disableUnderline={!mouseOver}
                                            tabIndex={3}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container direction={'column'} spacing={2}>
                                    <Grid item xs={12}>
                                        <InputLabel shrink>
                                            Type
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            id="item-type"
                                            value={state.specType}
                                            onChange={handleStateChangeEvent('specType')}
                                            disabled={disabled}
                                            disableUnderline={!mouseOver}
                                            tabIndex={4}
                                        >
                                            <MenuItem value={'pallet'}>Pallet</MenuItem>
                                            <MenuItem value={'crate'}>Crate</MenuItem>
                                            <MenuItem value={'block pallet'}>Block Pallet</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.topHeading}>Notes</Typography>
                                        <TextField
                                            style={{width: 380}}
                                            id="psi-notes"
                                            label=""
                                            multiline
                                            rows={1}
                                            rowsMax={5}
                                            // disableUnderline={!mouseOver}
                                            value={state.notes}
                                            onChange={handleStateChangeEvent('notes')}
                                            tabIndex={5}
                                            disabled={disabled}
                                            InputProps={{disableUnderline: !mouseOver}}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.totalLabel}>
                                    <Typography className={classes.topHeading}>
                                        Total Cost
                                    </Typography>
                                </div>
                                <Tooltip title={'Click to copy to clipboard'}>
                                    <div className={classes.large} onClick={() => {
                                        navigator.clipboard.writeText(totals.grandTotal.toFixed(2))
                                    }}>
                                        ${totals.grandTotal.toFixed(2)}
                                    </div>
                                </Tooltip>
                                <PriceChange diff={diff}/>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} style={{minHeight: 100}}>
                        <Grid container spacing={2} direction={'row'} alignContent={'space-between'}
                              justify={'space-between'}>
                            <Grid item xs={2}>
                                <Typography className={classes.topHeading}>Name</Typography>
                            </Grid>
                            {/*<Grid item xs={1}>*/}
                            {/*    <Typography className={classes.topHeading}>Height</Typography>*/}
                            {/*</Grid>*/}
                            <Grid item xs={1}>
                                <Typography className={classes.topHeading}>Width</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.topHeading}>Length</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.topHeading}>Quantity</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.topHeading}>Board Feet</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.topHeading}>Cost</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.topHeading}>Lab. Cost</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.topHeading}>Mat. Cost</Typography>
                            </Grid>
                        </Grid>
                        <ItemList
                            items={state.lineItems}
                            hourlyRate={state.hourlyRate}
                            onChange={handleLineItemsChange}
                            disabled={disabled}
                        />
                        {mouseOver ? <Button
                            fullWidth
                            variant={'outlined'}
                            startIcon={<AddIcon/>}
                            onClick={handleAddLine}
                            disabled={disabled}
                        >
                            Add Item
                        </Button> : <div style={{height: 36}}/>}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={'flex-end'}>
                            <Grid item xs={9}>
                                {/*<Paper className={classes.paper}>xs=6</Paper>*/}
                            </Grid>
                            <Grid item xs={3} container justify={'flex-end'} spacing={1} direction={'column'}
                                  alignContent={'flex-end'}>
                                <Grid container direction={'row'} justify={'space-between'}>
                                    <Grid item>
                                        Materials:
                                    </Grid>
                                    <Grid item>
                                        {(totals.itemsTotal).toFixed(2)}
                                    </Grid>
                                </Grid>
                                <Grid container direction={'row'} justify={'space-between'}>
                                    <Grid item>
                                        Waste {(state.wastePercent * 100).toFixed(0)}%:
                                    </Grid>
                                    <Grid item>
                                        {totals.waste.toFixed(2)}
                                    </Grid>
                                </Grid>
                                <Grid container direction={'row'} justify={'space-between'}>
                                    <Grid item>
                                        Fasteners {(state.fastenerPercent * 100).toFixed(0)}%:
                                    </Grid>
                                    <Grid item>
                                        {totals.fasteners.toFixed(2)}
                                    </Grid>
                                </Grid>
                                <Grid container direction={'row'} justify={'space-between'}>
                                    <Grid item style={{fontWeight: 600}}>
                                        Material Total:
                                    </Grid>
                                    <Grid item style={{fontWeight: 600}}>
                                        ${(totals.materialTotal).toFixed(2)}
                                    </Grid>
                                </Grid>
                                <Grid container direction={'row'} justify={'space-between'}>
                                    <Grid item style={{fontWeight: 600}}>
                                        Labor Total:
                                    </Grid>
                                    <Grid item style={{fontWeight: 600}}>
                                        ${totals.laborTotal.toFixed(2)}
                                    </Grid>
                                </Grid>
                                <Divider/>
                                <Grid container direction={'row'} justify={'space-between'}>
                                    <Grid item style={{fontWeight: 600, fontSize: '1.2em',}}>
                                        Total Cost:
                                    </Grid>
                                    <Grid item style={{fontWeight: 600, fontSize: '1.2em',}}>
                                        ${totals.grandTotal.toFixed(2)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    </Container>);
}
