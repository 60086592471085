import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {List, ListItem, ListItemText} from "@material-ui/core";

export default function SelectNewOrOldCostSheetDialog({
                                                          open = false,
                                                          onOpenNew: handleNew,
                                                          onOpenOld: handleOld,
                                                          onClose: handleClose,
                                                      }) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Prices Have Changed</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Some of the prices have changed since this Cost Sheet was created.
                    </DialogContentText>
                </DialogContent>
                <List>
                    <ListItem autoFocus button onClick={() => handleNew()}>
                        <ListItemText primary="Create a new Cost Sheet with current prices"/>
                    </ListItem>
                    <ListItem autoFocus button onClick={() => handleOld()}>
                        <ListItemText primary="Open the old Cost Sheet"/>
                    </ListItem>
                </List>
                <DialogActions onSubmit={() => handleNew()}>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}