import React, {useEffect, useState} from 'react';
import {Grid, IconButton, Input, makeStyles, Tooltip} from "@material-ui/core";
import useOnMouseOver from "./hooks/useOnMouseOver";
import DeleteIcon from '@material-ui/icons/Delete';
import {ItemType, LineItemType} from "./types";
import {calcBF, calcCutCost, calcLaborForLaborType, calcMaterialCost} from "./services/calculations";
import Typography from "@material-ui/core/Typography";
import {PartAutoComplete} from "./componants/PartAutoComplete";
import PriceChange from "./componants/PriceChange";
import {DateTime} from "luxon";
import {toDecimal} from "./services/formatter";
import {useObservable} from "rxjs-hooks";
import {itemListShared} from "./services/rpcs";
import {StalePriceDetail} from "./componants/StalePrice";

const useStyles = makeStyles((theme) => ({
    input: {paddingTop: 2},
    subText: {
        fontSize: theme.typography.pxToRem(11),
        color: theme.palette.text.hint,
    },
}));

type Props = {
    item: LineItemType;
    onChange: (v: LineItemType) => void;
    onDelete: (id: number, index: number) => void;
    index: number;
    parentItem?: ItemType;
    hourlyRate: number;
    disabled?: boolean;
}

export default function LineItem({
                                     item,
                                     onChange,
                                     onDelete,
                                     index,
                                     parentItem = null,
                                     hourlyRate,
                                     disabled = false
                                 }: Props) {
    const classes = useStyles();
    const [mouseOver] = useOnMouseOver();
    const [state, setState] = useState(item)
    const orgItems = useObservable(() => itemListShared, []);

    const orgItemsForLine: ItemType = orgItems?.filter((i: ItemType) => parseInt(i.id?.toString()) === parseInt(item.itemId?.toString()))[0];

    const oldestUpdatedDate = (orgItemsForLine?.costLastUpdated || orgItemsForLine?.creationDate);

    const costDiff = ((parseFloat(parentItem?.cost as string) || parseFloat(item.cost as string)) - parseFloat(item.cost as string));

    // Calculate line totals
    useEffect(() => {
        update('lineTotal', calcMaterialCost(state));
    }, [state.id, state.qty, state.lengthInches, state.cost, state.cutCost, state.itemType]);

    // Calculate cut cost
    useEffect(() => {
        update('cutCost', calcCutCost(state, hourlyRate));
    }, [state.id, state.qty, state.lengthInches, state.itemType]);

    // See if item needs to calculate board feet
    useEffect(() => {
        console.log(state);
        update('costIsBoardFeet', state.itemType === 'lumber' || state.itemType === 'plywood');
    }, [state.itemType]);

    useEffect(() => onChange(state), [state]);

    const update = (key, value) => setState(p => ({...p, [key]: value}));

    const qty = (parseFloat(state.qty.toString()) + (state.itemType === 'plywood' ? 1 : 0));
    const laborCostToolTip = <div>
        (({parseFloat(state.widthInches as any as string).toFixed(3)} inches &#247; 12) &#215; {state.cutMinutes} minutes &#215; {parseFloat(qty.toString()).toFixed(3)} cuts) &#215; (${parseFloat(hourlyRate.toString()).toFixed(3)}/hr &#247; 60)
        = ${parseFloat(state.cutCost?.toString()).toFixed(3)}
    </div>;


    return (
        <Grid container direction={'column'}>


            <Grid container spacing={2} direction={'row'} alignContent={'space-between'} justify={'space-between'}
                  style={{position: 'relative', fontSize: '1rem'}}>
                <Grid item xs={2}>
                    <PartAutoComplete
                        inputProps={{className: classes.input}}
                        value={state.name || ''}
                        onChange={v => update('name', v)}
                        disabled={disabled}
                        onSelect={(i: ItemType) => {
                            // console.log(i, state);

                            setState(prev => ({
                                ...state,
                                ...i,
                                itemId: i.id,
                                widthInches: i.widthInches || state.widthInches
                            }))
                        }}
                    />
                    <Typography style={{paddingTop: 0}} className={classes.subText}>{state.description}</Typography>
                </Grid>
                {/*<Grid item xs={1}>*/}
                {/*    {(state.costIsBoardFeet && !orgItemsForline?.heightInches) ?*/}
                {/*        <Input id="width"*/}
                {/*               style={{width: 60}}*/}
                {/*               value={toDecimal(state.heightInches)}*/}
                {/*               type={'number'}*/}
                {/*               onChange={e => update('heightInches', e.target.value)}*/}
                {/*               placeholder={mouseOver ? 'Height' : ''}*/}
                {/*               disabled={!state?.name}*/}
                {/*               disableUnderline={!mouseOver}*/}
                {/*               inputProps={{className: classes.input, step: .125, min: 0}}*/}
                {/*        /> : <span>{toDecimal(state.heightInches)}</span>}*/}
                {/*</Grid>*/}
                <Grid item xs={1}>
                    {(state.costIsBoardFeet && !orgItemsForLine?.widthInches) ?
                        <Input id="width"
                               style={{width: 60}}
                               value={toDecimal(state.widthInches)}
                               type={'number'}
                               onChange={e => update('widthInches', e.target.value)}
                               placeholder={mouseOver ? 'Width' : ''}
                               disabled={!state?.name || disabled}
                               disableUnderline={!mouseOver}
                               inputProps={{className: classes.input, min: 0, step: .125}}
                        /> : <span>{toDecimal(state.widthInches)}</span>}
                </Grid>
                <Grid item xs={1}>
                    {state.costIsBoardFeet &&
                    <Input id="length"
                           style={{width: 60}}
                           value={toDecimal(state.lengthInches)}
                           type={'number'}
                           onChange={e => update('lengthInches', e.target.value)}
                           placeholder={mouseOver ? 'Length' : ''}
                           disabled={!state?.name || disabled}
                           disableUnderline={!mouseOver}
                           inputProps={{className: classes.input, min: 0, step: .125}}
                    />}
                    <Typography className={classes.subText}>{state?.unitType}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Input id="quantity"
                           style={{width: 65}}
                           value={toDecimal(state.qty)}
                           onChange={e => update('qty', e.target.value)}
                           disableUnderline={!mouseOver}
                           inputProps={{className: classes.input, min: 0, step: .001}}
                           type="number"
                           disabled={disabled}
                    />
                </Grid>
                <Grid item xs={1}>
                    {state.costIsBoardFeet && calcBF(state.widthInches, (state.itemType === 'plywood' ? 1 : state.heightInches), state.lengthInches, state.qty).toFixed(3)}
                </Grid>
                <Grid item xs={1}>
                    {state.costIsBoardFeet ?
                        <span>{parseFloat(state.cost as string).toFixed(3)}</span>
                        : <Input id="cost"
                                 style={{width: 50}}
                                 value={toDecimal(state.cost)}
                                 onChange={e => update('cost', e.target.value)}
                                 disableUnderline={!mouseOver}
                                 inputProps={{className: classes.input, min: 0, step: .001}}
                                 type="number"
                        />}

                    <Grid direction={'row'} container>
                        {!!costDiff && <Tooltip
                            title={`The cost changed to ${parseFloat(parentItem.cost as string).toFixed(3)} on ${DateTime.fromSQL(parentItem.costLastUpdated || parentItem.creationDate).toFormat('MM/dd/yyyy')}`}>
                        <span>
                        <PriceChange diff={costDiff.toFixed(3)} align={'flex-start'} fractionDigits={3}/>
                            </span>
                        </Tooltip>}
                        <Typography style={{paddingTop: 0}}
                                    className={classes.subText}>
                            {state.costIsBoardFeet && 'BF'}
                        </Typography>
                    </Grid>

                </Grid>
                <Grid item xs={1}>
                    {state.costIsBoardFeet && <Tooltip title={laborCostToolTip}>
                        <span>{state.cutCost?.toFixed(3)}</span>
                    </Tooltip>}
                    {(!state.costIsBoardFeet && state.itemType === 'labor') &&
                    <span>{calcLaborForLaborType(state)}</span>}
                </Grid>
                <Grid item xs={2}>
                    {state.itemType !== 'labor' && '$' + state.lineTotal?.toFixed(3)}
                    {state.itemType !== 'labor' && <StalePriceDetail date={oldestUpdatedDate}/>}
                </Grid>
                {
                    mouseOver &&
                    <IconButton
                        disabled={disabled}
                        onClick={() => onDelete(state.id, index)}
                        style={{position: 'absolute', right: 0}}
                    >
                        <Tooltip title={'Delete'}>
                            <DeleteIcon style={{color: '#ccc'}} fontSize={'small'}/>
                        </Tooltip>
                    </IconButton>}
            </Grid>
            <Typography style={{paddingTop: 0}} className={classes.subText}>{state.importDescription}</Typography>
        </Grid>
    )
}
