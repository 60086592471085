import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import {ItemType} from "../types";
import {DateTime} from "luxon";
import {toDecimal} from "../services/formatter";

const defaultItem: ItemType = {
    name: '',
    cost: null,
    widthInches: null,
    heightInches: null,
    costIsBoardFeet: true,
    creationDate: DateTime.now().toSQLDate().toString(),
    itemType: 'lumber'
}

export default function NewItemModal({open, onClose, item = defaultItem, onSave: handleSave, title = 'Add New Item'}) {

    const [state, setState] = useState(item);

    const handleChange = (key, value) => {
        setState(p => ({...p, [key]: value || null}));
    };

    const handleClose = () => {
        onClose();
        setState(defaultItem);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        handleSave(state);
                        setState(defaultItem);
                        handleClose();
                    }}
                >
                    <DialogContent>
                        <DialogContentText>
                            {/*Create a new item*/}
                        </DialogContentText>
                        <Grid container direction={'column'}>
                            <Grid item container direction={'row'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    onChange={e => handleChange('name', e.target.value)}
                                    value={state.name}
                                    required
                                />
                            </Grid>
                            <Grid item container direction={'row'}>
                                <TextField
                                    margin="dense"
                                    id="description"
                                    label="Description"
                                    type="text"
                                    fullWidth
                                    onChange={e => handleChange('description', e.target.value)}
                                    value={state.description}
                                />
                            </Grid>
                            <Grid container direction={'row'} spacing={2} justify={'flex-start'}>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="dense"
                                        id="height"
                                        label="Height"
                                        type="number"
                                        fullWidth
                                        onChange={e => handleChange('heightInches', e.target.value)}
                                        value={toDecimal(state.heightInches)}
                                    />
                                    {state.itemType === 'plywood' &&
                                    <FormHelperText>1" for plywood BF calc</FormHelperText>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        margin="dense"
                                        id="width"
                                        label="Width"
                                        type="number"
                                        fullWidth
                                        onChange={e => handleChange('widthInches', e.target.value)}
                                        value={toDecimal(state.widthInches)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction={'row'} spacing={2}>
                                {/*<Grid item>*/}
                                {/*    <TextField*/}
                                {/*        margin="dense"*/}
                                {/*        id="length"*/}
                                {/*        label="Length"*/}
                                {/*        type="number"*/}
                                {/*        fullWidth*/}
                                {/*        onChange={e => handleChange('lengthInches', e.target.value)}*/}
                                {/*        value={toDecimal(state.lengthInches)}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={6}>
                                    <TextField
                                        margin="dense"
                                        id="cost"
                                        label="Cost"
                                        type="number"
                                        fullWidth
                                        onChange={e => handleChange('cost', e.target.value)}
                                        value={toDecimal(state.cost)}
                                        required
                                    />
                                </Grid>
                                <Grid item style={{marginTop: 5}} xs={6}>
                                    <InputLabel shrink>
                                        Type
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        id="item-type"
                                        value={state.itemType}
                                        onChange={e => handleChange('itemType', e.target.value)}
                                    >
                                        <MenuItem value={'lumber'}>Lumber</MenuItem>
                                        <MenuItem value={'plywood'}>Plywood</MenuItem>
                                        <MenuItem value={'labor'}>Labor</MenuItem>
                                        <MenuItem value={'hardware'}>Hardware</MenuItem>
                                        <MenuItem value={'misc'}>Misc</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl>
                                        <TextField
                                            margin="dense"
                                            id="cut-minutes"
                                            label="Cut Time"
                                            type="number"
                                            fullWidth
                                            onChange={e => handleChange('cutMinutes', e.target.value)}
                                            value={toDecimal(state.cutMinutes)}
                                        />
                                        <FormHelperText>Minutes to cut a linear foot</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} style={{width: 230}}>
                                    <fieldset>
                                        <legend> Amtech Item Sync</legend>
                                        <Grid container direction={'row'}>
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <TextField
                                                        margin="dense"
                                                        id="amtech-item-no"
                                                        label="Item No"
                                                        fullWidth
                                                        onChange={e => handleChange('amtechItemNo', e.target.value)}
                                                        value={state.amtechItemNo}
                                                    />
                                                    <FormHelperText>Amtech item to get price from</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <TextField
                                                        margin="dense"
                                                        id="amtech-item-length-inches"
                                                        label="Length"
                                                        fullWidth
                                                        onChange={e => handleChange('amtechItemLengthInches', e.target.value)}
                                                        type="number"
                                                        value={toDecimal(state.amtechItemLengthInches, 1)}
                                                    />
                                                    <FormHelperText>Length of Amtech item in inches</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>

                                {/*<Grid item xs={6}>*/}
                                {/*<TextField*/}
                                {/*    inputProps={{min: 0, max: 100}}*/}
                                {/*    margin="dense"*/}
                                {/*    id="waste"*/}
                                {/*    label="Waste"*/}
                                {/*    type="number"*/}
                                {/*    fullWidth*/}
                                {/*    onChange={e => handleChange('waste', e.target.value as any / 100)}*/}
                                {/*    value={toDecimal(state.waste * 100)}*/}
                                {/*/>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>

                        {/*<FormControlLabel*/}
                        {/*    control={<Switch*/}
                        {/*        checked={state.costIsBoardFeet}*/}
                        {/*        onChange={e => handleChange('costIsBoardFeet', !state.costIsBoardFeet)}*/}
                        {/*    />}*/}
                        {/*    label="Is Lumber"*/}
                        {/*/>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="primary">
                            Cancel
                        </Button>
                        <Button type={'submit'} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

        </div>
    )
        ;
}
