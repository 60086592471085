import React from 'react';
import {makeStyles} from "@material-ui/core";
import useMobileOpen from "./hooks/useMobileOpen";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    title: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: theme.typography.h4,
        [theme.breakpoints.down('sm')]: theme.typography.h5,
    },
}));

export default function MenuBar({title, actions, barStyle={}}) {

    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = useMobileOpen();
    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    return (<AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={barStyle}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
            >
                <MenuIcon/>
            </IconButton>
            <Typography variant="h4" className={classes.title}>
                {title}
            </Typography>

            {actions}
        </Toolbar>
    </AppBar>)
}