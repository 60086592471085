import {useObservable} from "rxjs-hooks";
import {BehaviorSubject} from "rxjs";

const s = new BehaviorSubject(false);

export default function useMobileOpen(defaultValue = false): [boolean, (v: boolean) => void] {

    const isMobile = useObservable<any>(() => s, false)

    const setMobile = (v) => s.next(v);

    return [isMobile, setMobile];
}
