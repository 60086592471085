import {CalcTotals, LineItemType, SpecCostSheetType} from "../types";

export const calcCost = (sheet: SpecCostSheetType): CalcTotals => calcCostFromLines(sheet.lineItems, sheet.wastePercent, sheet.fastenerPercent);

export const calcCostFromLines = (lines: LineItemType[] = [], wastePercent: number = 0, fastenerPercent: number = 0): CalcTotals => {
    const itemsTotal: number = lines.map(calcMaterialCost).reduce((prev, curr) => prev + curr, 0) as number;
    const waste = itemsTotal * wastePercent;
    const fasteners = itemsTotal * fastenerPercent;
    const laborTotal: number = lines.map(line => {
        return line.itemType === 'labor' ? line.cost as number * parseFloat(line.qty as string) : line.cutCost;
    }).reduce((prev, curr) => prev + curr, 0) as number;
    const materialTotal = itemsTotal + waste + fasteners;
    const grandTotal = materialTotal + laborTotal || 0;

    return {grandTotal, itemsTotal, waste, fasteners, materialTotal, laborTotal: laborTotal || 0};
}

export const calcLineItemsWithNewCosts = (lineItems = [], priceItems) => lineItems
    .flatMap((line) => {
            return priceItems
                .filter(i => i.id === line.itemId)
                .map(i => ({...line, cost: i.cost}))
                .reduce((accm, prev) => prev, line);
        }
    );

export const calcUpdatedList = (lineItems = [], priceItems) => lineItems
    .flatMap((line) => priceItems
        .filter(i => i.id === line.itemId)
        .filter(i => i.cost !== line.cost)
        .reduce((accum, prev) => {
            return {[line.id]: {item: prev, line}}
        }, null as any)
    )
    //combine everything into one keyed object
    .reduce((a, c) => ({...a, ...c}), {});

export const calcBoardFeetCost = (bf, partCost = 0.00) => ((partCost) * (bf || 0));

export const calcBF = (width, height, length, quantity): number => {
    return ((width || 0) * (height || 0) * ((parseFloat(length || '0')) / 12) * parseFloat(quantity || '0')) / 12;
}

export const calcMaterialCost = (line: LineItemType): number => {
    let cost;

    // Labor is added in elsewhere
    if (line.itemType === 'labor') {
        return 0;
    }

    const isBF = line.itemType === 'lumber' || line.itemType === 'plywood';

    if (isBF) {
        const bf = calcBF(line.widthInches, (line.itemType === 'plywood' ? 1 : line.heightInches), line.lengthInches, line.qty);
        cost = calcBoardFeetCost(bf, line.cost as number);
    } else {
        cost = line.cost as number * parseFloat(line.qty as string);
    }

    return cost;
}

export const calcLaborForLaborType = (line: LineItemType) => {
    return line.itemType === 'labor' ? line.cost as number * parseFloat(line.qty as string) : 0;
}

const calcTotalCutTime = (width: number = 0, cutTime: number = 0): number => (width / 12) * cutTime;

const calcCutCostTotal = (cutTime: number = 0, quantity: number = 0, rate = 0): number => {
    const minRate = rate / 60;
    return (cutTime * quantity) * minRate;
}

export const calcCutCost = (line: LineItemType, rate): number => {
    let qty = line.qty;

    if (line.itemType === 'plywood') {
        qty = parseInt(qty as string) + 1;
    }

    const time = calcTotalCutTime(line.widthInches, line.cutMinutes);

    return calcCutCostTotal(time, qty as number, rate);
}