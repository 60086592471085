import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {DeleteOutline, FileCopyOutlined} from "@material-ui/icons";
import Download from '@material-ui/icons/CloudDownloadOutlined';
import {useEventCallback} from "rxjs-hooks";
import {getImportedDocumentUrlCommand} from "./services/rpcs";
import {Observable} from "rxjs";

export default function MoreActionsMenu({onCopy, onArchive, disabled = false, showDownload = false, specId}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [onDownload] = useEventCallback(event$ => event$
        .switchMap((specId) => getImportedDocumentUrlCommand(specId))
        .do(url => window.open(url, '_blank')), ''
    )

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
                More Actions
                <ArrowDropDown/>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        onCopy();
                    }}
                    disabled={disabled}
                >
                    <FileCopyOutlined/>Copy
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        onArchive();
                    }}
                    disabled={disabled}
                >
                    <DeleteOutline/> Archive
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        onDownload(specId);
                    }}
                    disabled={disabled || !showDownload}
                >
                    <Download/> Download PDF
                </MenuItem>
            </Menu>
        </div>
    );
}
