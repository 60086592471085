import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ViewList from '@material-ui/icons/ViewList';

import {makeStyles, useTheme, Theme, createStyles} from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import useMobileOpen from "./hooks/useMobileOpen";
import PDFUpload from "./PDFUpload";
import ErrorAlert from "./componants/ErrorAlert";
import UserInfo from "./componants/UserInfo";

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            paddingTop: 0
        },
        active: {
            '&::before': {
                transform: "translateY(-50%)",
                content: '""',
                borderStyle: "solid",
                borderWidth: "6px 7px 6px 0",
                borderColor: "transparent darkgrey transparent transparent",
                position: "absolute",
                right: 0,
                top: "50%"
            }
        }
    }),
);

interface Props {
    window?: () => Window;
    children: any;
}

export default function Menu(props: Props) {
    const {children} = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useMobileOpen();

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const drawer = (
        <div>
            <div className={classes.toolbar}>
                <a href={'/'}>
                    <img src={'/crateZ_logo.png'} height={40} style={{margin: 8, marginLeft: 15}} alt={'logo'}/>
                </a>
            </div>
            <Divider/>
            <List>
                <ListItem button component={NavLink} to={'/cost-sheets'} activeClassName={classes.active}>
                    <ListItemIcon><ViewList/></ListItemIcon>
                    <ListItemText primary={'Cost Sheets'}/>
                </ListItem>

                <ListItem button component={NavLink} to={'/price-list'} activeClassName={classes.active}>
                    <ListItemIcon><LocalAtmIcon/></ListItemIcon>
                    <ListItemText primary={'Price List'}/>
                </ListItem>

            </List>
            <Divider/>
            <List>
                <ListItem>
                    <UserInfo/>
                </ListItem>
            </List>

        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <nav className={classes.drawer} aria-label="mailbox folders" id={'drawer'}>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{paper: classes.drawerPaper,}}
                        ModalProps={{keepMounted: true}}
                    >
                        {drawer}
                        <PDFUpload/>
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{paper: classes.drawerPaper}}
                        variant="permanent"
                        open
                    >
                        {drawer}
                        <PDFUpload/>
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <ErrorAlert/>
                {children}
            </main>
        </div>
    );
}
