import React, {useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuBar from "./MenuBar";
import {Button, Tooltip} from "@material-ui/core";
import NewItemModal from "./componants/NewItemModal";
import DeleteIcon from '@material-ui/icons/Delete';
import {useEventCallback, useObservable} from "rxjs-hooks";
import {saveItemRPC, deleteItemRPC, itemListRPC, refreshItemList, itemSync} from "./services/rpcs";
import {DateTime} from "luxon";
import DeleteItemDialog from "./componants/DeleteItemDialog";
import {Observable} from "rxjs";
import {ItemType} from "./types";
import {toDecimal} from "./services/formatter";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 650,
    },
    new: {
        position: 'relative',
        marginRight: 0,
        width: '50',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
        backgroundColor: '#36A900',
        color: 'white',
        '&.MuiButton-root:hover': {backgroundColor: 'rgba(54,169,0,0.6)'}
    },
    link: {
        // display: 'table-row',
        textAlign: 'initial',
    }
}));

export default function PriceList() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(null);

    const items = useObservable(() => itemListRPC(), []);
    const [itemToDelete, setItemToDelete] = useState(null);

    const [deleteItem] = useEventCallback((event$): Observable<any> => event$
        .switchMap((id) => deleteItemRPC(id))
        .do(() => setItemToDelete(null))
        .do(refreshItemList)
    );

    const [handleSave] = useEventCallback((event$): Observable<any> => event$
        .switchMap((item: ItemType) => saveItemRPC(item))
        .do(() => setEdit(null))
        .do(refreshItemList)
    )

    const [syncPrices, syncMessage] = useEventCallback((event$): Observable<any> => event$
        .switchMap(() => itemSync())
        .do((v) => console.log(v)), ''
    )

    console.log('syncMessage', syncMessage);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setEdit(null);
    }

    return (
        <div>
            <DeleteItemDialog item={itemToDelete} onDelete={(i) => deleteItem(i.id)}
                              onClose={() => setItemToDelete(null)}/>
            <NewItemModal open={open} onClose={handleClose} onSave={handleSave}/>
            {edit &&
            <NewItemModal
                open={!!edit}
                onClose={handleClose}
                onSave={handleSave}
                item={edit}
                title={`Edit Item ${edit.id}`}
            />}

            <MenuBar
                title={'Items'}
                actions={<>

                    <Tooltip title={'Sync prices with Amtech'}>
                        <Button
                            color="inherit"
                            // className={classes.new}
                            variant={'outlined'}
                            onClick={syncPrices}
                        >
                            Sync
                        </Button>
                    </Tooltip>
                    <Button
                        color="inherit"
                        className={classes.new}
                        variant={'outlined'}
                        onClick={handleOpen}
                    >
                        Add Item
                    </Button>
                </>}
            />
            <>
                {syncMessage && <Alert style={{width: '100%'}} severity="info">{syncMessage} </Alert>}
            </>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Height</TableCell>
                            <TableCell>Width</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Last Updated</TableCell>
                            <TableCell>Type</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((row, index) => (
                            <TableRow
                                key={row.name + index}
                                onClick={() => {
                                    setEdit(row);
                                }}
                                // component={ButtonBase}
                                className={classes.link}
                                hover
                            >

                                <TableCell scope="row">{row.name}</TableCell>
                                <TableCell scope="row">{toDecimal(row.heightInches)}</TableCell>
                                <TableCell scope="row">{toDecimal(row.widthInches)}</TableCell>
                                <TableCell scope="row">{toDecimal(row.cost)}</TableCell>
                                <TableCell
                                    scope="row">{DateTime.fromSQL(row.creationDate).toFormat('M/dd/yyyy')}</TableCell>
                                <TableCell
                                    scope="row">{DateTime.fromSQL(row.costLastUpdated || row.creationDate).toFormat('M/dd/yyyy')}</TableCell>
                                <TableCell scope="row">{row.itemType}</TableCell>

                                <span style={{position: 'absolute', right: 35, marginTop: 15}}>
                                        <Tooltip title={'Delete'}>
                                            <DeleteIcon
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setItemToDelete(row);
                                                }}
                                                style={{color: '#ccc'}} fontSize={'small'}/>
                                        </Tooltip>
                                    </span>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
