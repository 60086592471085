import React, {useEffect, useState} from "react";
import {Alert, AlertTitle} from "@material-ui/lab";
import {setErrorAlert, useErrors} from "../hooks/useErrors";
import {isEmpty} from 'lodash';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Error';

export default function ErrorAlert() {
    const error = useErrors();
    const [open, setOpen] = useState(false);

    // Scroll to the top of the page when there is an error
    useEffect(() => window.scrollBy({top: -window.outerHeight, behavior: 'smooth'}), [error]);

    return (
        <div style={{marginBottom: 10}}>
            {!isEmpty(error) &&
            <div>
                <Alert
                    icon={<CheckIcon fontSize="inherit" onClick={() => setOpen(true)}/>}
                    severity={error?.severity || 'error'}
                    onClose={() => setErrorAlert({})}
                >
                    <AlertTitle>{error?.title}</AlertTitle>
                    {error?.message}
                    <div>
                        If the problem persists <Button variant={'outlined'} color="inherit" size="small"
                                                        onClick={() => window.location.reload()}>
                        Reload
                    </Button> the page.
                    </div>
                </Alert>
                <ErrorInfoDialog open={open} onClose={() => setOpen(false)} error={error}/>
            </div>}

        </div>
    )
}

function ErrorInfoDialog({error, open, onClose: handleClose}) {
    return (<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Info for: {error.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {error.message}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                {JSON.stringify(error.error)}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                Close
            </Button>
        </DialogActions>
    </Dialog>)
}