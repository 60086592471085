import * as React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Switch} from "react-router";
import CostSheet from "./CostSheet";
import PriceList from "./PriceList";
import Menu from "./Menu";
import CostSheetList from "./CostSheetList";
import {useProgress} from "./hooks/useProgress";
import {LinearProgress} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {setAuth0} from "./services/wamp";
import Loading from "./componants/Loading";
import Logout from "./componants/Logout";

export default function App() {
    const showProgress = useProgress();

    const context = useAuth0();
    useEffect(() => context && setAuth0(context), [context]);

    return (
        context.isAuthenticated ? <div style={{marginTop: 70}} id="main">
            <div style={{
                position: 'fixed',
                // zIndex: 2000,
                width: '100%',
                margin: -5
            }}>
                {showProgress && <LinearProgress variant={'indeterminate'}/>}
            </div>
            <Router>
                <Menu>
                    <Switch>
                        <Route path={['/price-list/']} component={PriceList}/>
                        <Route path={['/price-doc/:id']} component={CostSheet}/>
                        <Route path={['/cost-sheet/:specNo/:id']} component={CostSheet}/>
                        <Route path={['/cost-sheet/:specNo']} component={CostSheet}/>
                        <Route path={['/cost-sheets/:expanded/:selected/:page']} component={CostSheetList}/>
                        <Route path={['/price-docs/']} component={CostSheet}/>
                        <Route path={['/logout/']} component={Logout}/>
                        <Route path={['/']} component={CostSheetList}/>
                    </Switch>
                </Menu>
            </Router>
        </div> : <Loading/>
    )
}
