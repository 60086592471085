import React from 'react';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

export default function PriceChange({diff, prefix = '', suffix = '', align = 'flex-end', fractionDigits = 2}) {

    const up = diff > 0;

    if (diff === 0) {
        return null;
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: align,
            alignItems: 'center',
            fontSize: '0.6875rem',
            color: up ? 'rgb(97, 26, 21)' : 'rgb(30, 70, 32)'
        }}>
            {prefix}
            <div style={{
                backgroundColor: up ? 'rgb(253, 236, 234)' : 'rgb(237, 247, 237)',
                borderRadius: 3,
                textAlign: 'right',
                paddingRight: 5,
                paddingLeft: 5,
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'right',
                marginRight: 5
            }}>
                {up ? <UpIcon style={{fontSize: 12, marginRight: 3, marginBottom: 2}}/> :
                    <DownIcon style={{
                        fontSize: 12,
                        marginRight: 3,
                        marginBottom: 2
                    }}/>} {Math.abs(diff).toFixed(fractionDigits)} {suffix}
            </div>
        </div>
    );
}