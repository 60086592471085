import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {useObservable} from "rxjs-hooks";
import {getCostSheetListPageCountRPC} from "../services/rpcs";

export default function CostSheetListPagination() {

    const history = useHistory();
    const pageCount: number = useObservable(() => getCostSheetListPageCountRPC()
            .pluck('specCount')
            .map((v: number) => Math.ceil(v / 20))
        , 1);

    const {page = '1'} = useParams<{ selected: string, expanded: string, page: string }>();

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) =>
        history.replace(`/cost-sheets/0/0/${value}`);

    return (
        <div style={{marginTop: 10}}>
            <Pagination
                count={pageCount}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
                defaultPage={parseInt(page)}
            />
        </div>
    );
}
