import {useEffect, useState} from "react";
import {ItemType, LineItemType} from "../types";
import {useObservable} from "rxjs-hooks";
import {itemListShared} from "../services/rpcs";
import {calcCostFromLines, calcLineItemsWithNewCosts, calcUpdatedList} from "../services/calculations";
import {minBy} from 'lodash';

export function useAgingCosts(lineItems: LineItemType[], wastePercent = 0, fastenerPercent = 0) {

    const items = useObservable(() => itemListShared, []);
    const [state, setState] = useState({diff: 0, updatedList: {}, newTotals: {}, oldestCosts: {}});

    useEffect(() => {

        const lineItemsWithNewCosts = calcLineItemsWithNewCosts(lineItems, items);
        const newTotals = calcCostFromLines(lineItemsWithNewCosts, wastePercent, fastenerPercent);
        const orgTotals = calcCostFromLines(lineItems, wastePercent, fastenerPercent);
        const diff = newTotals.grandTotal - orgTotals.grandTotal;
        const updatedList = calcUpdatedList(lineItems, items);

        const oldestCosts = (lineItems || [])
            .map((line) => {
                const is = items.filter(i => i.id === line.itemId);
                const oldest = minBy(is, (i: ItemType) => i.costLastUpdated || i.creationDate);
                return {[line.id]: {item: oldest, line}}
            })
            //combine everything into one keyed object
            .reduce((a, c) => ({...a, ...c}), {});

        setState({diff, updatedList, newTotals, oldestCosts})

    }, [lineItems, items]);

    return {...state};
}
