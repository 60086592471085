import React, {useMemo} from 'react';
import SadIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import {darken, lighten, Tooltip} from "@material-ui/core";
import {DateTime} from "luxon";
import {useTheme} from "@material-ui/core/styles";

export default function StalePrice({date, align = 'flex-end'}) {
    const {days} = useMemo(() => DateTime.now().diff(DateTime.fromSQL(date), ["days"]), [date]);
    const {root, pill, icon} = useStaleStyles(days, align);

    const tooltipText = `You have some items that have not had price changes in ${Math.ceil(days)} days.`;
    const text = `Items ${Math.ceil(days)} days old`

    return (days > 30) && (
        <Tooltip title={tooltipText}>
            <div style={root}>
                <div style={pill}><SadIcon style={icon}/> {text}</div>
            </div>
        </Tooltip>
    );
}

export function StalePriceDetail({date, align = 'flex-start'}) {
    const {days} = useMemo(() => DateTime.now().diff(DateTime.fromSQL(date), ["days"]), [date]);
    const {root, pill, icon} = useStaleStyles(days, align);

    const tooltipText = `This item has not had a price change in ${Math.ceil(days)} days.`;
    const text = `${Math.ceil(days)} days old`

    return (days > 30) && (
        <Tooltip title={tooltipText}>
            <div style={root}>
                <div style={pill}><SadIcon style={icon}/> {text}</div>
            </div>
        </Tooltip>
    );
}

const useStaleStyles = (days = 0, align) => {
    const theme = useTheme();

    let colors = {contrastText: theme.palette.text.primary, light: '#ccc'};
    const x = Math.ceil(days);
    switch (true) {
        case (x > 90):
            colors = theme.palette.error;
            break;
        case (x > 60):
            colors = theme.palette.warning;
            break;
        case (x > 30):
            colors = theme.palette.info;
            break;
    }

    const {light, contrastText} = colors;
    const bgColor = lighten(light, 0.9);
    const color = darken(contrastText, 0.6)

    return {
        root: {
            display: 'flex',
            justifyContent: align,
            alignItems: 'center',
            fontSize: '0.6875rem',
            color: color,
            alignContent: 'center'
        },
        pill: {
            backgroundColor: bgColor,
            borderRadius: 3,
            // textAlign: 'right',
            paddingRight: 5,
            paddingLeft: 5,
            display: 'flex',
            alignItems: 'center',
            marginRight: 5,
        },
        icon: {
            fontSize: 12,
            marginRight: 3,
            marginBottom: 2
        }
    }
}