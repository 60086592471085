import React, {useState} from 'react';
import {useDropzone, FileWithPath} from "react-dropzone";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Grid, LinearProgress, List, ListItem, ListItemText, Tooltip, Typography} from "@material-ui/core";
import {useEventCallback, useObservable} from "rxjs-hooks";
import {Observable} from "rxjs/Observable";
import {fileUpload} from "./services/http";
import {getSignedURL, getSpecFromDocument} from "./services/rpcs";
import {Alert} from "@material-ui/lab";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {margin: 15},
        baseStyle: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',

            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#6e7e90',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#666666',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        activeStyle: {borderColor: '#2196f3'},
        acceptStyle: {borderColor: '#00e676'},
        rejectStyle: {borderColor: '#ff1744'}
    })
);

export default function PDFUpload() {

    const history = useHistory();

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({maxFiles: 1});
    const classes = useStyles();

    const fileTypesFilter = (file: FileWithPath) =>
        file.type === 'application/pdf'
    // || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // || file.type === 'application/vnd.ms-excel';

    const [setFiles, daFiles]: [any, any] = useEventCallback((event$, _, input$) =>
            input$
                .map(([f]) => f.filter(fileTypesFilter))
                .merge(event$)
                .distinctUntilChanged()
        , [], [acceptedFiles]);

    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('');

    const upload = useObservable((_, input$) => input$
            .flatMap(([f]) => f)
            .filter(fileTypesFilter)
            .distinctUntilChanged()
            .do(() => setError(null))
            .groupBy((file: FileWithPath) => file.path)
            .flatMap(group$ => group$
                .switchMap((file: FileWithPath) => {
                    setStatus('Uploading...');
                    return getSignedURL(file.name)
                        .catch(e => {
                            setProgress(0);
                            setStatus('');
                            setError('Could not get a signed URL.');
                            setFiles([]);
                            return Observable.empty();
                        })
                        .map(r => [r.url, file, r.key]);
                })
                .switchMap(([url, file, key]: [string, FileWithPath, string]) => {
                    setStatus('Extracting Text...');
                    setProgress(-1);
                    return Observable
                        .from(fileUpload(url, file, (e: ProgressEvent) => setProgress((e.loaded / e.total) * 100)))
                        .catch(e => {
                            setProgress(0);
                            setStatus('');
                            setError('Error uploading file.');
                            setFiles([]);
                            return Observable.empty();
                        })
                        .flatMap(r => getSpecFromDocument(key)
                            .catch(e => {
                                setProgress(0);
                                setStatus('');
                                setError('Error reading spec info');
                                setFiles([]);
                                return Observable.empty();
                            })
                        )
                        .map((v) => ({...v, SpecCost: {...v.SpecCost, s3Key: key}}));
                })
            )
            .catch(e => {
                setProgress(0);
                setError(e.message);
                setFiles([]);
                return Observable.never();
            })
            .do(v => {
                if (v.JobStatus === "SUCCEEDED") {
                    setStatus('Done');
                    setFiles([]);
                    const sheet = {
                        ...v.SpecCost,
                        hourlyRate: 65,
                    };

                    history.push({pathname: '/cost-sheets/'});
                    history.push({pathname: '/cost-sheet/new', state: {sheet}});
                }

                if (v.JobStatus === 'IN_PROGRESS') {
                    setProgress(-1);
                }
            })
        , null, [acceptedFiles]);

    const files = daFiles.map((file: FileWithPath, i) => (
        <ListItem key={file.path}>
            <Grid container direction={'column'}>
                <Tooltip title={file.name}>
                    <ListItemText style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                        {file.name}
                    </ListItemText>
                </Tooltip>
                <Typography variant={'body1'}>{status}</Typography>
                <LinearProgress
                    style={{
                        width: '80%',
                        position: 'absolute',
                        bottom: 0
                    }}
                    variant={progress > 0 ? 'determinate' : 'indeterminate'}
                    value={progress}
                />
            </Grid>
        </ListItem>
    ));

    return (
        <section className={classes.container}>
            <div {...getRootProps()} className={classes.baseStyle}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a spec sheet here, or click to select files</p>
            </div>
            <aside>
                <List>
                    {!error && files}
                    {error && <Alert severity={'error'}>
                        {error}
                    </Alert>}
                </List>
            </aside>
        </section>
    );
}
