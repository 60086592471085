import * as Thruway from 'thruway.js';
import {ReplaySubject, Subject} from "rxjs";
import {Auth0ContextInterface} from "@auth0/auth0-react/src/auth0-context";
import {Observable} from "rxjs/Observable";

const auth0Subject: Subject<Auth0ContextInterface> = new ReplaySubject(1);

export const setAuth0 = (a: Auth0ContextInterface) => auth0Subject.next(a);

const authenticate = auth0Subject
    .delay(500)
    .switchMap(({getAccessTokenSilently, loginWithRedirect, getIdTokenClaims}) => {
        return Observable.from(getAccessTokenSilently())
            .catch(e => loginWithRedirect())
            .flatMap(t => getIdTokenClaims());
    });

// const url = 'ws://localhost:9283/ws';
const url = `wss://${window.location.hostname}/ws`;

export const wamp = new Thruway.Client(
    url,
    'psi',
    {
        'authmethods': ['psi']
    });

wamp.onChallenge((challenge) => challenge
        .switchMap(c => authenticate)
        .do(v => console.log(v))
        .pluck('__raw')
    // .mapTo('Hello')
);

wamp.topic('test').subscribe();


