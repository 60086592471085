import React, {useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button, Grid, Tooltip} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import MenuBar from "./MenuBar";
import {useEventCallback, useObservable} from "rxjs-hooks";
import {itemListShared, specCostSheet, specCostSheetList} from "./services/rpcs";
import {chain} from 'lodash';
import {SpecCostSheetType} from "./types";
import {DateTime} from "luxon";
import {useParams} from "react-router";
import CostSheetListPagination from "./componants/CostSheetListPagination";
import PriceChange from "./componants/PriceChange";
import StalePrice from "./componants/StalePrice";
import {calcUpdatedList} from "./services/calculations";
import SelectNewOrOldCostSheetDialog from "./componants/SelectNewOrOldCostSheetDialog";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(16),
            // flexBasis: '25%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        topHeading: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.text.secondary,
        },
        cost: {
            fontSize: theme.typography.pxToRem(16),
            flexShrink: 0,
            fontWeight: 600
        },
        add: {
            position: 'relative',
            marginRight: 0,
            width: '50',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
            backgroundColor: '#36A900',
            color: 'white',
            '&.MuiButton-root:hover': {backgroundColor: 'rgba(54,169,0,0.6)'}
        },
        accordionContent: {
            '& .MuiAccordionSummary-content': {marginTop: 6, marginBottom: 6}
        },
    }),
);

export default function CostSheetList() {
    const history = useHistory();
    const classes = useStyles();
    const {
        selected = '0',
        expanded = '0',
        page = '1'
    } = useParams<{ selected: string, expanded: string, page: string }>();

    const [search, setSearch] = useState(null);

    const sheets = useObservable((_, input$) =>
        input$
            .debounceTime(100)
            .switchMap(([v, s]) => specCostSheetList(v, s).retryWhen(e => e.delay(1000)))
            .map(s => chain(s)
                .groupBy('specNo')
                .value()
            ), null, [page, search]);

    const handleExpandClick = (panel: string) => {
        const ex = panel === expanded ? '0' : panel;

        history.replace(`/cost-sheets/${ex}/${selected}/${page}`);
    };

    return (
        <div className={classes.root}>
            <TextField
                margin="dense"
                id="search"
                label="Search"
                type="search"
                size="small"
                onChange={e => setSearch(e.target.value)}
                value={search}
            />
            <MenuBar
                title={'Cost Sheets'}
                actions={<Button color="inherit" className={classes.add} variant={'outlined'}
                                 onClick={() => history.push('/cost-sheet/new')}>New Cost Sheet</Button>}
            />
            <Grid container direction={'row'} justify="space-between" style={{paddingLeft: 16, paddingRight: 50}}>
                <Grid item xs={2}>
                    <Typography className={classes.topHeading}>Client/Spec Number</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography className={classes.topHeading}>Description/Customer Item</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.topHeading}>Date</Typography>
                </Grid>
                <Grid item xs={3} style={{maxWidth: 100}}>
                    <Typography className={classes.topHeading}>Cost</Typography>
                </Grid>
            </Grid>
            {sheets && Object.values(sheets).map((grouped: SpecCostSheetType[], groupedIndex) => {
                return <Accordion key={groupedIndex + grouped[0].id} expanded={expanded === `expand${groupedIndex}`}>
                    <AccordionSummary
                        expandIcon={
                            (grouped.length > 1) ? <Tooltip title={`History ${grouped.length - 1}`}>
                                <ExpandMoreIcon onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleExpandClick(`expand${groupedIndex}`)
                                }}/>
                            </Tooltip> : <div style={{width: 24}}/>
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className={classes.accordionContent}
                    >
                        <Line sheet={grouped[0]} parent={true}/>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container direction={'column'} style={{marginLeft: 20, marginRight: 36}}>
                            {grouped.slice(1).map((v, i) => <Line sheet={v} key={i}/>)}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            })}
            {sheets && <CostSheetListPagination/>}
        </div>
    );
}

type LineProps = {
    sheet: SpecCostSheetType;
    parent?: boolean
};

function Line({sheet, parent = false}: LineProps) {
    const classes = useStyles();
    const history = useHistory();
    const priceItems = useObservable(() => itemListShared, []);

    const {
        selected = '0',
        expanded = '0',
        page = '1'
    } = useParams<{ selected: string, expanded: string, page: string }>();

    const weSelected = selected as any as number === sheet.id;
    const priceDiff = (parseFloat(sheet?.totalCostCurrent as string)) - parseFloat(sheet?.totalCost as string);
    const date = useMemo(() => DateTime.fromSQL(sheet.specDate).toFormat('LLL dd yyyy'), [sheet]);
    const ref = useRef<HTMLButtonElement>(null);

    //Scroll to the selected item
    useEffect(() => {
        if (weSelected) {
            ref.current && ref.current.scrollIntoView({behavior: 'auto', block: 'center'});
        }
    }, [weSelected, ref]);

    // Scroll to the top of the page when the page numbe changes
    useEffect(() => window.scrollBy({top: -window.outerHeight, behavior: 'smooth'}), [page]);

    const totalCost = parseFloat(sheet?.totalCostCurrent.toString()) + parseFloat(sheet.laborCost.toString());

    const [openDialog, setOpenDialog] = useState(false);

    const [createNewWithLatestPrices] = useEventCallback((event$, _, input$) => event$
            .switchMap(id => specCostSheet(id))
            .withLatestFrom(input$)
            .do(([sheet, [priceItems]]) => {
                const updatedList = calcUpdatedList(sheet.lineItems, priceItems);
                const newSheet = {
                    ...sheet,
                    id: null,
                    specDate: DateTime.now().toFormat('yyyy-MM-dd'),
                    lineItems: sheet.lineItems.map(l => {
                        const newCost = updatedList[l.id]?.item?.cost || l.cost;

                        return {...l, id: DateTime.now().toMillis(), cost: newCost};
                    })
                };
                history.push({pathname: '/cost-sheet/new', state: {sheet: newSheet}});
            })
        , null, [priceItems]);


    const navigate = () => {
        history.replace(`/cost-sheets/${expanded}/${sheet.id}/${page}`);
        if (parent) {
            history.push(`/cost-sheet/${sheet.specNo}`)
        } else {
            history.push(`/cost-sheet/${sheet.specNo}/${sheet.id}`)
        }
    }

    const handleLineClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (parent && priceDiff !== 0) {
            return setOpenDialog(true);
        }

        navigate();
    };

    const handleOpenOld = () => navigate();
    const handleOpenNew = () => createNewWithLatestPrices(sheet.id);

    return (
        <div style={{backgroundColor: weSelected ? '#eeeeee' : 'initial', width: '100%'}}>
            <SelectNewOrOldCostSheetDialog
                open={openDialog}
                onOpenNew={handleOpenNew}
                onOpenOld={handleOpenOld}
                onClose={() => setOpenDialog(false)}
            />
            <Button
                size={'small'}
                ref={ref}
                fullWidth
                onClick={handleLineClick}
                style={{textTransform: 'none', textAlign: 'inherit'}}
            >
                <Grid container direction={'row'} justify="space-between">
                    <Grid item xs={2}>
                        <Typography className={classes.heading}>{sheet.specNo}</Typography>
                        <Typography className={classes.secondaryHeading}>{sheet.customerName}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.heading}>{sheet.description}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{date}</Typography>
                        <StalePrice date={sheet.oldestCostChange} align={'flex-start'}/>
                    </Grid>
                    <Grid item xs={3} style={{maxWidth: 100}}>
                        <Typography
                            className={classes.cost}>${parseFloat(totalCost.toString()).toFixed(2)}</Typography>
                        {/*<Typography className={classes.secondaryHeading} style={{textAlign: 'right'}}>draft</Typography>*/}
                        <Tooltip title={'Create new sheet with latest pricing'}>
                            <div onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                createNewWithLatestPrices(sheet.id);
                            }}>
                                <PriceChange diff={priceDiff} align={'flex-start'}/>
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Button>
        </div>
    )
}
