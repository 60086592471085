import {BehaviorSubject} from "rxjs";
import {useObservable} from "rxjs-hooks";

const state = new BehaviorSubject(false)

export default function useOnMouseOver(): [boolean, (v: boolean) => void] {

    function setMouseOver(v: boolean) {
        state.next(v)
    }

    const isOver = useObservable(() => state);

    return [isOver, setMouseOver];
}
