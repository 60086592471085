import React, {useRef, useState} from 'react';
import {Input, makeStyles, MenuItem, Paper, Popper} from "@material-ui/core";
import useOnMouseOver from "../hooks/useOnMouseOver";
import Autosuggest from 'react-autosuggest';
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {useObservable} from "rxjs-hooks";
import {itemListShared} from "../services/rpcs";
import {ItemType} from "../types";

const useStyles = makeStyles((theme: any) => ({
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing?.value,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    }
}));

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function renderSuggestion(suggestion, {query, isHighlighted}) {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) =>
                        part.highlight ? (
                            <span key={String(index)} style={{fontWeight: 500}}>
              {part.text}
            </span>
                        ) : (
                            <strong key={String(index)} style={{fontWeight: 300}}>
                                {part.text}
                            </strong>
                        ),
                )}
            </div>
        </MenuItem>
    );
}

export function PartAutoComplete({inputProps: i, value: v, onChange, onSelect, disabled = false}) {
    const classes = useStyles();

    const [mouseOver] = useOnMouseOver();

    const [value, setValue] = useState(v);
    const [suggestions, setSuggestions] = useState([])

    const parts:ItemType[] = useObservable(() => itemListShared, []);

    const getSuggestions = (value) => {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }
console.log(escapedValue)
        const regex = new RegExp('' + escapedValue , 'i');

        return parts.filter(v => regex.test(v.name));
    }


    const handleChange = (event, {newValue}) => {
        setValue(p => newValue);
        onChange(newValue);
    };

    const handleSelect = (e, {suggestion, suggestionValue}) => {
        onSelect(suggestion, suggestionValue);
    };

    const onSuggestionsFetchRequested = ({value: vv}) => {
        setSuggestions(getSuggestions(vv))
    };

    const onSuggestionsClearRequested = () => setSuggestions([]);

    const inputProps = {
        ...i,
        placeholder: mouseOver ? 'Enter a name' : '',
        value,
        onChange: handleChange
    };

    const inputRef = useRef(null);

    return (
        <Autosuggest
            onSuggestionSelected={handleSelect}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderSuggestionsContainer={options => (
                <Popper anchorEl={inputRef.current} open={Boolean(options.children)}>
                    <Paper
                        square
                        {...options.containerProps}
                        style={{
                            width: inputRef.current ? inputRef.current.clientWidth : null,
                            left: inputRef.current ? -inputRef.current.clientWidth / 2 : null
                        }}
                    >
                        {options.children}
                    </Paper>
                </Popper>
            )}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            inputProps={inputProps}
            renderInputComponent={
                (iprops) => <Input
                    {...iprops}
                    inputProps={iprops}
                    color={null}
                    autoFocus
                    aria-autocomplete={'none'}
                    autoComplete={'off'}
                    disableUnderline={!mouseOver}
                    required
                    disabled={disabled}
                    ref={inputRef}
                    // onChange={handleChange}
                />
            }
        />
    );
}
