import React from 'react';
import UserIcon from '@material-ui/icons/AccountCircleOutlined';
import {useAuth0} from "@auth0/auth0-react";
import {Tooltip} from "@material-ui/core";

export default function UserInfo() {
    const {user, logout} = useAuth0();

    return (
        <Tooltip title={'Click to logout'}>
            <div>
                <div style={{display: 'flex', alignItems: 'center'}}
                     onClick={() => logout({returnTo: window.location.origin})}>
                    <UserIcon height={12}/> {user?.name}
                </div>
            </div>
        </Tooltip>
    );
}