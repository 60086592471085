import React, {useEffect, useState} from 'react';
import {Divider, Grid} from "@material-ui/core";
import LineItem from "./LineItem";
import {LineItemType} from "./types";
import {useAgingCosts} from "./hooks/useAgingCosts";

type Props = {
    items: LineItemType[];
    onChange: (i: LineItemType[]) => void;
    hourlyRate: number;
    disabled?: boolean;
}

export default function ItemList({items: lineItems, onChange, hourlyRate, disabled = false}: Props) {

    const [updatedItems, setUpdatedItems] = useState<any>([]);
    const {updatedList} = useAgingCosts(lineItems);
    const [scroll, setScroll] = useState(true);

    // This is a hack.  Scroll to the top of the page during the initial page load.  Wait 2 seconds after it's finished loading and then disable it
    useEffect(() => {
        scroll && window.scrollTo(0, 0)
        const timeout = setTimeout(() => setScroll(false), 2000)
        return () => clearTimeout(timeout);
    }, [updatedItems, scroll])

    return (
        <div>
            <Grid container spacing={2} direction={'row'} style={{marginBottom: 20}}>
                {(lineItems || []).map((item: LineItemType, i) =>
                    <Grid key={i + item.id} item xs={12}>
                        <LineItem
                            hourlyRate={hourlyRate}
                            parentItem={updatedList && updatedList[item.id] && updatedList[item.id].item}
                            index={i}
                            item={{...item}}
                            disabled={disabled}
                            onChange={(v) => {
                                // console.log('line change', v);
                                setUpdatedItems(p => {
                                    p[i] = v;
                                    return [...p];
                                })
                                onChange(updatedItems)
                            }}
                            onDelete={(id, index) => {
                                setUpdatedItems((prev: LineItemType[]) => {
                                    let deleteIndex;

                                    if (id) {
                                        deleteIndex = prev.findIndex((i) => i.id.toString() === id.toString());
                                        // onDelete(id);  // Send it up to the parent so that we can delete
                                    } else {
                                        deleteIndex = index;
                                    }

                                    prev.splice(deleteIndex, 1);
                                    return [...prev];
                                });
                                onChange(updatedItems)
                            }}
                        />
                        <Divider style={{marginTop: 10}} light/>
                    </Grid>)}
            </Grid>
        </div>
    );
}
