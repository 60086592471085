import {BehaviorSubject} from "rxjs";
import {useObservable} from "rxjs-hooks";

const subject = new BehaviorSubject(false);

export function useProgress() {
    return useObservable(() => subject, false);
}

export const startProgress = () => subject.next(true);
export const endProgress = () => subject.next(false);