import axios, {AxiosResponse} from "axios";

const http = axios.create({
    // baseURL: "http://localhost:8080",
    // headers: {
    //     "Content-type": "application/json"
    // }
});

export default http;

export const fileUpload = (url, file, onUploadProgress): Promise<AxiosResponse> => {
    const formData = new FormData();

    formData.append("file", file);

    return http.put(url, formData, {
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress,
    });
};