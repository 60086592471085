import * as React from "react";
import './Loading.css';
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";

export default function Logout() {

    const {logout} = useAuth0();

    useEffect(() => {
        logout({returnTo: window.location.origin})
    }, []);

    return (
        <div className='lcontainer'>
            <div className='loader'>
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                <div className='logout--text'/>
            </div>
        </div>
    );
}