import {BehaviorSubject} from "rxjs";
import {useObservable} from "rxjs-hooks";
import {ErrorAlert} from "../types";
import {wamp} from "../services/wamp";

const subject = new BehaviorSubject<ErrorAlert>({});

export function useErrors() {
    return useObservable(() => subject);
}

export const setErrorAlert = (e: ErrorAlert) => subject.next(e);

wamp.onOpen
    .withLatestFrom(subject)
    .subscribe(([_, e]) => {
        if (e.message === 'The WebSocket connection was closed') {
            setErrorAlert({})
        }
    });