import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";

ReactDOM.render(
    // <React.StrictMode> // Material-ui needs to update some libs before we can use strict mode
    <Auth0Provider
        domain="dev-wjyw6dqk.us.auth0.com"
        clientId="vuuyFi5tVScDUYQBpPd4yKbTfjwODsW4"
        redirectUri={window.location.origin}
    >
        <App/>
    </Auth0Provider>
    // </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
