import {wamp} from './wamp';
import {BehaviorSubject, Observable} from "rxjs";
import {ItemType, SpecCostSheetType, User} from "../types";
import {endProgress, startProgress} from "../hooks/useProgress";
import {setErrorAlert} from "../hooks/useErrors";

// Helper Functions
export const getArg0 = ({args}) => args[0];
const pCall = (uri, args: any[] = []) => {
    startProgress();
    return wamp.progressiveCall(uri, [...args])
        .do({error: (e) => setErrorAlert({title: 'Connection Error', message: e.message, error: e, severity: 'error'})})
        .map(getArg0)
        .do(endProgress);
}

/***** COST SPEC SHEET ****/
export const specCostSheetList = (page, search = null): Observable<SpecCostSheetType[]> => pCall('cratez.spec_cost_list', [page - 1, 20, search && `${search.trim()}:*`]);
export const getCostSheetListPageCountRPC = (): Observable<number> => pCall('cratez.spec_cost_page_count');

export const specCostSheet = (id): Observable<SpecCostSheetType> => pCall('cratez.get_spec_cost', [id]);
export const specCostSheetBySpecNo = (specNo): Observable<SpecCostSheetType> => pCall('cratez.get_spec_cost_by_spec_no', [specNo]);

export const saveSpecCostSheet = (costSheet: SpecCostSheetType): Observable<SpecCostSheetType> => pCall('cratez.save_spec_cost', [costSheet]);
export const archiveSpecCostSheet = (id): Observable<string> => pCall('cratez.archive_spec_cost', [id]);


/***** ITEMS ****/
export const saveItemRPC = (item: ItemType): Observable<ItemType> => pCall('cratez.save_item', [item]);
export const deleteItemRPC = (id): Observable<string> => pCall('cratez.delete_item', [id]);

export const refreshItemList = new BehaviorSubject(null);
export const itemListRPC = (): Observable<ItemType[]> => refreshItemList.switchMapTo(pCall('cratez.item_list', []));
export const itemListShared = itemListRPC().shareReplay(1);

export const searchItem = (name: string): Observable<ItemType[]> => pCall('cratez.search_item', [name]);
export const itemSync = () => pCall('cratez.item_sync', []);

/***** USER ****/
export const getUserInfo = (): Observable<User> => pCall('cratez.user_info', []);

/***** PDF UPLOAD ****/
export const getSignedURL = (fileName: string): Observable<any> => pCall('cratez.get_s3_put_url', [fileName]);
export const getSpecFromDocument = (fileName: string): Observable<any> => pCall('cratez.get_spec_from_document', [fileName]);
export const getImportedDocumentUrlCommand = (specId): Observable<any> => pCall('cratez.get_imported_document_url', [specId]);
