import * as React from "react";
import './Loading.css';

export default function Loading() {
    return (
        <div className='lcontainer'>
            <div className='loader'>
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                {/*<div className='loader--dot'/>*/}
                <div className='loader--text'/>
            </div>
        </div>
    );
}